
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnChanges {
   @Input() notificationTitle = '';
   @Input() notificationDescription = '';
   @Input() notificationUrl: string = '';

   @Output() closeEmit = new EventEmitter();
   constructor( private router: Router){

   }

   ngOnInit(): void {

   }
   ngOnChanges(): void {

   }


   stopPropagation(event){
      event.stopPropagation();
      this.closeEmit.emit()
   }

   redirect(){
      if(!this.notificationUrl){
         return
      }
      this.closeEmit.emit()
      this.router.navigate([this.notificationUrl])
   }
   

   
   

}