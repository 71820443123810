import { NavigationRoutingModule } from './pages/navigation/navigation-routing.module';
import { NotFoundModule } from './utils/pages/not-found/not-found.module';
import { ChangePasswordModule } from './utils/dialogs/change-password/change-password.module';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Injectable } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPtBrPaginatorIntl } from './utils/pt-br-label-paginator/pt-br-paginator-intl';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { LottieModule } from 'ngx-lottie';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy, registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import player from 'lottie-web';
import { InterceptorService } from './core/services/crud/interceptor.service';
import { SocketService } from './core/services/socket/socket.service';
import { Socket, SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { AgmCoreModule } from '@agm/core';
import { InformationModule } from './utils/dialogs/information/information.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StoreConfigurationComponent } from './pages/store-configuration/store-configuration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
registerLocaleData(ptBr)
// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}


const config: SocketIoConfig = { url: environment.urlSocket , options: {
    transports: ['websocket'],
}};

/*
@Injectable()
export class SocketChat extends Socket {
  constructor () {
    super({ url: environment.urlSocket, options: {
      transports: ['websocket'],
    } })
  }
} */

@NgModule({
  declarations: [
    AppComponent,
    StoreConfigurationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    FontAwesomeModule,
    MatDialogModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    NavigationRoutingModule,
    NgxMaskModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    ChangePasswordModule,
    NotFoundModule,
    SocketIoModule.forRoot(config),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD4Dx_pOUPoDlTUQ1ZEov8__nfAvMHy4ao',
      libraries: ['places']
    }),
    MatSnackBarModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getPtBrPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    SocketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
