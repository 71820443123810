import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pretty'
})
export class PrettyPipe implements PipeTransform {

  transform(val) {
    console.log(val);
    const teste = val ;
    console.log(teste);
    return teste;
  }

}
