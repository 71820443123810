<div mat-dialog-content class="d-flex align-items-center justify-content-center">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <i [hidden]="data.error" class="far fa-check-circle fa-3x" style="color: forestgreen;"></i>
            <i [hidden]="!data.error" class="far fa-times-circle fa-3x" style="color: red ;"></i>
        </div>
        <div class="row justify-content-center">
            <h2>{{data.message}}</h2>
        </div>
        <div *ngIf="data.error && data?.returnError" class="row" >
            <button class="mb-2" mat-stroked-button (click)="teste()" > Detalhes 
                <mat-icon *ngIf="!openDetails">expand_more</mat-icon>
                <mat-icon *ngIf="openDetails">expand_less</mat-icon>
            </button>
            <div *ngIf="openDetails" class="col-12 p-0 m-0 d-flex justify-content-between py-2 position-relative">
                <div class="w-100 p-2" style="background-color: #fafafa;">
                    <pre>{{data?.returnError | json}}</pre> 
                  
                    
                </div>
              
                <button mat-icon-button  matTooltip="Copiar erro" style="position: absolute; top:10px; right: 10px;"  type="button" (click)="copyToClipboard()"  > <mat-icon>content_copy</mat-icon></button>

            </div>
        </div>
    </div>
</div>
<div mat-dialog-action class="d-flex align-items-center justify-content-center">
    <button class="btn-information" mat-raised-button color="primary" (click)="close()">Fechar</button>
</div>