import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

constructor( private socket: Socket) { }


  socketChatId(chat_id){
    console.log(this.socket);
    return this.socket.fromEvent<any>(`chats-${chat_id}-new-message`);
  }

  unsubscribeChat(chat_id){
    this.socket.removeListener(`chats-${chat_id}-new-message`, (res) => {
      console.log(this.socket)
    })
    console.log(this.socket)
  }

  socketNewOrder(seller_id){
    return this.socket.fromEvent<any>(`new-order-to-seller-${seller_id}`);
  }

  unsubscribeNewOrder(seller_id){
    this.socket.removeListener(`new-order-to-seller-${seller_id}`, (res) => {
      console.log(this.socket)
    })
    console.log(this.socket)
  }
  
  disconnect(){
    this.socket.disconnect();
  }

  connect(){
    this.socket.connect();
  }

  printSocket(){
    console.log(this.socket);
  }

}
