import { MatDialog } from '@angular/material/dialog';
import { StorageKey } from '../../storage/storage.model';
import { StorageService } from '../../storage/store.service';
import { RegisterManagerStore } from '../../../pages/store/register-manager-store/_models/RegisterManagerStore';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root'
})
export class SellersUsersService extends CrudService {

  endpoint = 'seller-users'

  constructor(http: HttpClient, dialog: MatDialog) {
    super(http, dialog);
  }

  public async saveSeller(id, body): Promise<any> {
    let response = null;
    try {
      response = await this.http
        .post(this.url + '/' + this.endpoint + '/' + id + '/users', body)
        .toPromise();
    } catch (error) {
      response = this.errorHandler('POST', error);
    }
    return response;
  };

  // public async updateSeller(id, body): Promise<any> {
  //   let response = null;
  //   try {
  //     response = await this.http
  //       .put(this.url + '/' + this.endpoint + '/' + id , body)
  //       .toPromise();
  //   } catch (error) {
  //     response = this.errorHandler('POST', error);
  //   }
  //   return response;
  // };

}
