import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/images/wine-bottle.json',
  };


  constructor(
    private eRef: ElementRef
  ) { }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }
}
