import { InformationComponent } from './../../utils/dialogs/information/information.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ChangePasswordComponent } from 'src/app/utils/dialogs/change-password/change-password.component';
import { StorageKey } from 'src/app/core/storage/storage.model';
import { StorageService } from 'src/app/core/storage/store.service';
import { Role } from '../auth/_models/Role.enum';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SellersUsersService } from 'src/app/core/services/sellers-users/sellers-users.service';
import { LoadingComponent } from 'src/app/utils/dialogs/loading/loading.component';
import { StoreConfigurationComponent } from '../store-configuration/store-configuration.component';
import { SellersService } from 'src/app/core/services/sellers/sellers.service';
import { SocketService } from 'src/app/core/services/socket/socket.service';

const { USER_ID, USER_NAME, USER_ROLE, SELLER_ID, SELLER_USERS_ID } = StorageKey;

const itemsMenu = [
  {
    name: 'Dashboard',
    hidden: false,
    role: [Role.ADMIN],
    routerLink: '/main/dashboard/main-dashboard',
    class: 'analytics'
  },
  {
    name: 'Dashboard',
    hidden: false,
    role: [Role.MANAGER],
    routerLink: '/main/dashboard/manager-dashboard',
    class: 'analytics'
  },
  {
    name: 'Lojas',
    hidden: false,
    role: [Role.ADMIN],
    children: [
      {
        hidden: false,
        name: 'Lojas cadastradas',
        role: [Role.ADMIN],
        routerLink: '/main/store/register-store',
        class: 'shop'
      },

    ],
  },
  {
    name: 'Acessos',
    hidden: false,
    role: [Role.ADMIN],
    children: [
      {
        hidden: false,
        name: 'Administrativos',
        role: [Role.ADMIN],
        routerLink: '/main/store/register-manager-store',
        class: 'user-admin'
      },
      {
        hidden: false,
        name: 'Clientes',
        role: [Role.ADMIN],
        routerLink: '/main/store/customer-records',
        class: 'user-client'
      },
    ],
  },

  {
    name: 'Produtos',
    hidden: false,
    role: [Role.ADMIN, Role.MANAGER],
    children: [
      {
        hidden: false,
        name: 'Produtos cadastrados',
        role: [Role.MANAGER],
        routerLink: '/main/products/product',
        class: 'box'
      },
      {
        hidden: false,
        name: 'Marcas',
        role: [Role.ADMIN],
        routerLink: '/main/products/brands',
        class: 'brand'
      },
      // {
      //   hidden: false,
      //   name: 'Produtos em destaque',
      //   role: [Role.ADMIN],
      //   routerLink: '/main/products/featured',
      //   class: 'product'
      // },
      {
        hidden: false,
        name: 'Promoções',
        role: [Role.MANAGER, ],
        routerLink: '/main/products/promotion',
        class: 'shopping-online'
      },
      {
        hidden: false,
        name: 'Cupons',
        role: [Role.ADMIN, ],
        routerLink: '/main/cupons',
        class: 'cupons'
      },
      {
        hidden: false,
        name: 'Categorias',
        role: [Role.ADMIN],
        routerLink: '/main/products/category',
        class: 'categories'
      },
      {
        hidden: false,
        name: 'Subcategorias',
        role: [Role.ADMIN],
        routerLink: '/main/products/subcategory',
        class: 'maintenance'
      },
      {
        hidden: false,
        name: 'Dinâmicos',
        role: [Role.ADMIN],
        routerLink: '/main/products/dynamic-info-type',
        class: 'maintenance'
      }


    ]
  },
  {
    name: 'Pedidos',
    hidden: false,
    role: [Role.MANAGER, Role.ATTENDANT, Role.ADMIN],
    children: [
      {
        hidden: false,
        name: 'Pedidos Marketplace',
        role: [Role.MANAGER, Role.ATTENDANT, Role.ADMIN],
        routerLink: '/main/requests/received',
        class: 'delivery-box',
        // badge: 'newOrders',
        // badgeValue: '5'
      },
      {
        hidden: false,
        name: 'Pedidos Delivery',
        role: [Role.MANAGER, Role.ATTENDANT, Role.ADMIN],
        routerLink: '/main/requests/received-delivery',
        class: 'delivery-box',
        // badge: 'newOrders',
        // badgeValue: '5'
      },
      {
        hidden: false,
        name: 'Chats',
        role: [ Role.ADMIN],
        routerLink: '/main/list-chats',
        class: 'chats',
        // badge: 'newOrders',
        // badgeValue: '5'
      },
    ]
  },
  {
    name: 'Pagamento',
    hidden: false,
    role: [Role.ADMIN],
    children: [
      {
        hidden: false,
        name: 'Métodos de pagamento',
        role: [Role.ADMIN],
        routerLink: '/main/payment-methods',

        class: 'wallet'
      },
    ],
  },
  {
    name: 'Banners',
    hidden: false,
    role: [Role.ADMIN],
    children: [
      {
        hidden: false,
        name: 'Cadastro de banners',
        role: [Role.ADMIN],
        routerLink: '/main/banners',

        class: 'banners'
      },
    ],
  },
  {
    name: 'FAQ',
    hidden: false,
    role: [Role.ADMIN],
    children: [
      {
        hidden: false,
        name: 'Cadastro faq',
        role: [Role.ADMIN],
        routerLink: '/main/faq-admin',
        class: 'faq'
      },
    ],
  },
  {
    name: 'Envios',
    hidden: false,
    role: [Role.MANAGER],
    children: [
      {
        hidden: false,
        name: 'Integração',
        role: [Role.MANAGER],
        routerLink: '/main/shipping',
        class: 'integration-shipment',
      },
    ]
  },
  {
    name: 'Opções de Delivery',
    hidden: false,
    role: [Role.MANAGER],
    routerLink: '/main/delivery-configurations',
    class: 'user-admin'

  },
  {
    hidden: false,
    name: 'Venda com a gente',
    role: [Role.ADMIN],
    routerLink: '/main/sell-with-us',
    class: 'united'
  },
  {
    name: 'Newsletter',
    hidden: false,
    role: [Role.ADMIN],
    routerLink: '/main/newsletter',
    class: 'newsletter',
  },
  {
    hidden: false,
    name: 'Enviar Notificações',
    role: [ Role.ADMIN],
    routerLink: '/main/notify',
    class: 'push-notification',
    // badge: 'newOrders',
    // badgeValue: '5'
  },


];

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  routerLink: string;
  level: number;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  notificacoes: any[]= []
  sound: HTMLAudioElement;
  userName: string = this.storage.read(USER_NAME);
  userRole: string = this.storage.read(USER_ROLE);
  userId: string = this.storage.read(USER_ID);
  sellerId: string = this.storage.read(SELLER_ID);
  sellerUserId: string = this.storage.read(SELLER_USERS_ID);

  loading: boolean = true;

  currentTitle: string;
  title: string;

  seller: any;

  dialogChangePassword: MatDialogRef<ChangePasswordComponent>;
  dialogLoading: MatDialogRef<LoadingComponent>;
  dialogInformation: MatDialogRef<InformationComponent>;
  dialogConfigurations: MatDialogRef<StoreConfigurationComponent>;

  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      class: node.class,
      routerLink: node.routerLink,
      hidden: node.hidden,
      badge: node.badge,
      badgeValue: node.badgeValue
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  openLoading = this.dialog.open(LoadingComponent, { disableClose: true, panelClass: 'loading-dialog-container' });
  closeLoading = this.openLoading.close();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private storage: StorageService,
    private router: Router,
    private dialog: MatDialog,
    private sellersUserService: SellersUsersService,
    private sellersService: SellersService,
    private socketService: SocketService
  ) {
    this.sound = new Audio('assets/audio/message.mp3');
    this.sellerId = this.storage.read(SELLER_ID);
    this.sellerUserId = this.storage.read(SELLER_USERS_ID);
    console.log(this.sellerId);
    console.log(this.sellerUserId);
    router.events.subscribe(() => {

      if (this.router.url.includes('/main/store/register-store')) {
        this.currentTitle = "Lojas"
      } else if (this.router.url.includes('/main/store/register-manager-store')) {
        this.currentTitle = "Gestores de lojas"
      } else if (this.router.url.includes('/main/products/product')) {
        this.currentTitle = "Produtos"
      } else if (this.router.url.includes('/main/requests/received')) {
        this.currentTitle = "Pedidos Marketplace"
      } else if (this.router.url.includes('/main/requests/received-delivery')) {
        this.currentTitle = "Pedidos Delivery"
      } else if (this.router.url.includes('/main/delivery-configurations')) {
        this.currentTitle = "Configuração de Delivery"
      } else if (this.router.url.includes('/main/products/brands')) {
        this.currentTitle = "Marcas"
      } else if (this.router.url.includes('/main/products/category')) {
        this.currentTitle = "Categorias"
      } else if (this.router.url.includes('/main/products/subcategory')) {
        this.currentTitle = "Subcategorias"
      } else if (this.router.url.includes('/main/products/featured')) {
        this.currentTitle = "Produtos em destaque"
      } else if (this.router.url.includes('/main/products/promotion')) {
        this.currentTitle = "Promoções"
      } else if (this.router.url.includes('/main/payment-methods')) {
        this.currentTitle = "Métodos de pagamento"
      } else if (this.router.url.includes('/main/banners')) {
        this.currentTitle = "Cadastro de banners"
      } else if (this.router.url.includes('/main/newsletter')) {
      this.currentTitle = "Newsletter"
      }
      else {
        this.currentTitle = "Painel administrativo"
      }

    });
    if(this.sellerId){
      this.socketNewOrder()
    }
  }

  
  ngOnInit(): void {
    // this.checkPermission(itemsMenu);
    this.loading = true;
    if(this.sellerUserId){
      this.getInformationSeller();
    } else {
      this.checkPermission(itemsMenu, [])
      this.dataSource.data = itemsMenu;
      this.loading = false;
    }
  }

  soundPlay() {
    this.sound.loop = false;
    this.sound.load();
    this.sound.play();
  }

  soundPause() {
    this.sound.pause();
  }

  checkRole() {
    if (this.userRole == Role.ADMIN) {
      this.title = 'eubebo';
    } else {
      this.title = this.seller.seller.display_name;
    }
  }

  checkPermission(itemsMenu, plataformTypesSeller) {
    console.log(plataformTypesSeller);
    
    itemsMenu.forEach(m => {
      if(m.name == 'Pedidos Marketplace' && !plataformTypesSeller.includes('Marketplace')){
        m.hidden = true;
        if (m.children) {
          this.checkPermission(m.children, plataformTypesSeller);
        }
      } else if(m.name == 'Opções de Delivery' && !plataformTypesSeller.includes('Delivery')){
        m.hidden = true;
        if (m.children) {
          this.checkPermission(m.children, plataformTypesSeller);
        }
      } else if(m.name == 'Pedidos Delivery' && !plataformTypesSeller.includes('Delivery')){
        m.hidden = true;
        if (m.children) {
          this.checkPermission(m.children, plataformTypesSeller);
        }
        
      } else if(m.name == 'Envios' && !plataformTypesSeller.includes('Marketplace')){
        m.hidden = true;
        if (m.children) {
          this.checkPermission(m.children, plataformTypesSeller);
        }
        
      } else if (m.role.includes(this.userRole)) {
        m.hidden = false;
        if (m.children) {
          this.checkPermission(m.children, plataformTypesSeller);
        }
      } else {
        m.hidden = true;
      }
    })
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  changePassword() {
    this.dialogChangePassword = this.dialog.open(ChangePasswordComponent, {
      disableClose: true,
      panelClass: 'container-add',
    });
  }

  openConfiguration() {
    this.dialogConfigurations = this.dialog.open(StoreConfigurationComponent, {
      disableClose: true,
      panelClass: 'container-add',
    })
  }

  logout() {
    this.authService.logout();
    this.socketService.unsubscribeNewOrder(this.sellerId)
    this.router.navigate(['auth/login'], { replaceUrl: true });
  }

  getInformationSeller() {
    // this.openLoading;
    this.sellersUserService.getById(this.sellerUserId).then(response => {
      this.seller = response;
      console.log(response);
    }).catch(error => {
      console.log(error)
      this.dialogInformation = this.dialog.open(InformationComponent, {
        panelClass: 'container-add',
        disableClose: true,
        data: {
          error: true,
          message: error.error.message
        }
      })
    }).finally(() => {
   // this.closeLoading;
    })

    this.sellersService.getById(this.sellerId).then(response => {
      console.log(response);
      let plataformTypesSeller = response.platform_types.map(item => item.name);
      this.checkPermission(itemsMenu, plataformTypesSeller);
      this.checkRole();

      this.dataSource.data = itemsMenu;
      this.loading = false;
    
    }).catch(error => {
      console.log(error)
      this.dialogInformation = this.dialog.open(InformationComponent, {
        panelClass: 'container-add',
        disableClose: true,
        data: {
          error: true,
          message: error.error.message
        }
      })
    }).finally(() => {
   // this.closeLoading;
    })

  }

  changeStore(){
    this.socketService.unsubscribeNewOrder(this.sellerId)

    this.router.navigate(['']);

  }

  socketNewOrder(){
    this.socketService.socketNewOrder(this.sellerId).subscribe({
      next: (data) => {
        console.log(data);
        if(!data?.update_status){
          this.notificacoes.push({title:"Novo Pedido", mensage: "Você tem um novo Pedido. ver mais", data});
          this.soundPlay()
        } else {
          this.notificacoes.push({title:"Pedido Pago", mensage: "Você tem um pedido que acaba se ser pago. ver mais", data});
          this.soundPlay()
        }
        
      }, 
      error: (err)=> {
        console.log(err);
      }
    })
  }

  closeNotification(index){
    this.notificacoes.splice(index, 1);
  }

}
