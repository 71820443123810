import { MatDialog } from '@angular/material/dialog';
import { StorageKey } from '../../core/storage/storage.model';
import { StorageService } from '../../core/storage/store.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/core/services/crud/crud.service';

const { AUTH_TOKEN, USER_NAME, USER_ID, USER_ROLE} = StorageKey;
@Injectable({
  providedIn: 'root'
})

export class AuthService extends CrudService{

  endpoint = 'users/auth'
  url = environment.apiUrl
  redirectUrl: string;
  token: string;

  constructor(http: HttpClient, private storage: StorageService, dialog: MatDialog) {
    super(http, dialog);
    this.token = this.storage.read(AUTH_TOKEN) || '';
    this.redirectUrl = '/';
  }

  public async login(body): Promise<any> {
    let response = null;
    try {
      response = await this.post(body);

      this.token = response.access_token;
      this.storage.save(AUTH_TOKEN, this.token);
      this.storage.save(USER_ID, response.data.id);
      this.storage.save(USER_NAME, response.data.name);
      this.storage.save(USER_ROLE, response.data.role);



      return this.redirectUrl;

    } catch (error) {
      response = this.errorHandler('POST', error);
    }
    return response;
  }

  public isLogged(): boolean {
    return this.token.length > 0;
  }

  public logout() {
    this.token = '';
    this.storage.removeAll();
  }

  public errorHandler(
    method: string,
    error: HttpErrorResponse,
  ): Promise<never> {
    console.error(
      `Error occurred during ${method} ${this.url + this.endpoint}`,
      error,
    );
    return Promise.reject(error);
  }


}
