import { NotFoundComponent } from './utils/pages/not-found/not-found.component';
import { PermissionGuard } from './pages/auth/guard/permission.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Role } from './pages/auth/_models/Role.enum';

const routes: Routes = [
  {
    path: '',
    data: {
      roles: [Role.MANAGER, Role.ADMIN, Role.ATTENDANT]
    },
    loadChildren: () =>
      import('./pages/splash-screen/splash-screen.module').then(
        m => m.SplashScreenModule,
      ),
  },
  {
    data: {
      roles: [Role.MANAGER, Role.ADMIN, Role.ATTENDANT]
    },
    path: 'main',
    loadChildren: () =>
      import('./pages/navigation/navigation.module').then(
        m => m.NavigationModule,
      ),
  },
  {
    data: {
      roles: [Role.MANAGER, Role.ADMIN, Role.ATTENDANT]
    },
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then(
        m => m.AuthModule ,
      ),
  },
  { path: 'manager-dashboard', loadChildren: () => import('./pages/dashboard/manager-dashboard/manager-dashboard.module').then(m => m.ManagerDashboardModule) },
  { path: 'dynamic-info-type', loadChildren: () => import('./pages/products/dynamic-info-type/dynamic-info-type.module').then(m => m.DynamicInfoTypeModule) },
  {
    path: '**',
    component: NotFoundComponent,
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
