<section>
  <div class="d-flex flex-column align-items-center">
    <h2>Alterar senha</h2>
    <div class="d-flex flex-column align-items-center">
      <h3>É recomendável usar uma senha forte que você não esteja usando em nenhum outro lugar.</h3>
      <button class="change-password-button" (click)="changePassword()">Alterar senha</button>
    </div>
  </div>
  <mat-divider style="color: rgb(199, 199, 199); width: 100%; margin-top: 1vh; margin-bottom: 1vh;"></mat-divider>
<!--   <div class="change-work-time-button" (click)="changeWorkTime()">
    <div class="change-work-time-text">
      <h2>Editar funcionamento</h2>
      <h3>Defina o horário de funcionamento de seu comércio.</h3>
    </div>
    <mat-icon>arrow_forward_ios</mat-icon>
  </div> -->
</section>
