import { HttpErrorResponse, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { InformationComponent } from 'src/app/utils/dialogs/information/information.component';

import { LoadingComponent } from 'src/app/utils/dialogs/loading/loading.component';


@Injectable()
export class InterceptorService implements HttpInterceptor {

  dialogLoading: MatDialogRef<LoadingComponent>;
  dialogInformation: MatDialogRef<InformationComponent, any>;


  constructor(private authService: AuthService, private router: Router, private dialog: MatDialog) { }

  intercept(req: HttpRequest<any>, next: HttpHandler, ){

    if(req.url.includes('http')){
      if(!this.dialogLoading){
        this.dialogLoading = this.dialog.open(LoadingComponent, { disableClose: true, panelClass: 'loading-dialog-container' })

      }

    }

    return next.handle(req).pipe(

      finalize(()=> {
        if(req.url.includes('http')){
          setTimeout(() => {

            if(this.dialogLoading){
              this.dialogLoading.close();
              this.dialogLoading = null;
            }
          }, 500);
        }
      }),
      catchError((error: HttpErrorResponse)=> {

        if(error.status === 401){
          this.authService.logout();

          this.router.navigate(['/']);
        }
        console.log(error)
        this.dialogInformation = this.dialog.open(InformationComponent, {
          panelClass: 'container-add',
          disableClose: true,
          data: {
            error: true,
            message: error.error.message
          }
        })
        return throwError(error);
      })


    );
  }

}
