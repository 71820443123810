import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'eubebo';



  constructor(
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,


  ) {

    this.matIconRegistry.addSvgIcon('analytics', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/analytics.svg"));
    this.matIconRegistry.addSvgIcon('box', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/box.svg"));
    this.matIconRegistry.addSvgIcon('brand', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/brand.svg"));
    this.matIconRegistry.addSvgIcon('categories', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/categories.svg"));
    this.matIconRegistry.addSvgIcon('group', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/group.svg"));
    this.matIconRegistry.addSvgIcon('maintenance', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/maintenance.svg"));
    this.matIconRegistry.addSvgIcon('product', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/product.svg"));
    this.matIconRegistry.addSvgIcon('shop', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/shop.svg"));
    this.matIconRegistry.addSvgIcon('shopping-online', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/shopping-online.svg"));
    this.matIconRegistry.addSvgIcon('historic', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/historic.svg"));
    this.matIconRegistry.addSvgIcon('tracking', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/tracking.svg"));
    this.matIconRegistry.addSvgIcon('wallet', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/wallet.svg"));
    this.matIconRegistry.addSvgIcon('delivery-box', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/delivery-box.svg"));
    this.matIconRegistry.addSvgIcon('cupons', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/cupons.svg"));
    this.matIconRegistry.addSvgIcon('advertisement', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/advertisement.svg"));
    this.matIconRegistry.addSvgIcon('banners', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/banners.svg"));
    this.matIconRegistry.addSvgIcon('faq', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/faq.svg"));
    this.matIconRegistry.addSvgIcon('united', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/united.svg"));
    this.matIconRegistry.addSvgIcon('whatsapp', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/whatsapp.svg"));
    this.matIconRegistry.addSvgIcon('integration-shipment', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/shipment.svg"));
    this.matIconRegistry.addSvgIcon('newsletter', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/newsletter.svg"));
    this.matIconRegistry.addSvgIcon('user-admin', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/user-admin.svg"));
    this.matIconRegistry.addSvgIcon('user-client', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/user-client.svg"));
    this.matIconRegistry.addSvgIcon('chats', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/chats.svg"));
    this.matIconRegistry.addSvgIcon('push-notification', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/push-notification.svg"));
    this.matIconRegistry.addSvgIcon('scan', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/scan.svg"));
    this.matIconRegistry.addSvgIcon('pix', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/pix.svg"));
    this.matIconRegistry.addSvgIcon('retornavel', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/reutilizavel.svg")); 
  }
}
