import { NavigationRoutingModule } from './../../../pages/navigation/navigation-routing.module';
import { LottieModule } from 'ngx-lottie';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found.component';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    LottieModule,
    MatButtonModule,    
    NavigationRoutingModule,
  ]
})
export class NotFoundModule { }
