import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChangePasswordComponent } from 'src/app/utils/dialogs/change-password/change-password.component';
import { SellersService } from 'src/app/core/services/sellers/sellers.service';
import { StorageService } from 'src/app/core/storage/store.service';

@Component({
  selector: 'app-store-configuration',
  templateUrl: './store-configuration.component.html',
  styleUrls: ['./store-configuration.component.scss']
})
export class StoreConfigurationComponent implements OnInit {

  dialogChangePassword: MatDialogRef<ChangePasswordComponent>;

  constructor(
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
  }

  changePassword() {
    this.dialogChangePassword = this.dialog.open(ChangePasswordComponent, {
      disableClose: true,
      panelClass: 'container-add',
    });
  }

/*   changeWorkTime() {
    this.dialogChangeWorkTime = this.dialog.open(WorkTimeComponent, {
      panelClass: 'container-add',
    });
  } */
}
