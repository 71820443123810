import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StorageKey } from '../../../core/storage/storage.model';
import { StorageService } from '../../../core/storage/store.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { InformationComponent } from 'src/app/utils/dialogs/information/information.component';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
    constructor(
        private router: Router,
        private storageService: StorageService,
    ) { }

    dialogInformation: MatDialogRef<InformationComponent>;

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const existToken: any = this.storageService.read(StorageKey.AUTH_TOKEN);
        const currentRole: any = this.storageService.read(StorageKey.USER_ROLE);

        if (existToken) {
            if (route.data.roles.includes(currentRole)) {
                return true;
            } else {
                this.router.navigate(['not-found']);
                return false;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['auth/login']);
        return false;
    }
}