
<mat-sidenav-container class="sidenav-container "  autosize>

  <mat-sidenav #drawer class="sidenav" fixedInViewport mode="side" [opened]="(isHandset$ | async) === true">

    <!-- <mat-toolbar>Menu</mat-toolbar> -->
    <div class="d-flex align-items-center justify-content-center p-2">
      <img src="/assets/images/Sìmbolo-roxo.png" width="100" alt="">
    </div>
    <mat-divider></mat-divider>
    <!-- <mat-nav-list *ngFor="let item of itemsMenu">
      <a mat-list-item routerLink="{{item.routerLink}}">
        <span style="width: 25%">
          <i ngClass="{{item.class}}"></i>
        </span>
        <span class="text-left" style="width: 75%">
          {{item.name}}
        </span>
      </a>
    </mat-nav-list> -->

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" matRippleDisabled class="p-3" *ngIf="!loading">
      <!-- This is the tree node template for leaf nodes -->
      <div class="d-flex align-items-center justify-content-start ">

        <mat-tree-node *matTreeNodeDef="let node;" matTreeNodePadding [hidden]="node.hidden">
          <!-- use a disabled button to provide padding for tree leaf -->

          <button *ngIf="node.badge" mat-stroked-button class="w-100  text-left" [routerLink]="node.routerLink"
            [matBadge]="node.badgeValue" matBadgeColor="accent">
            <!-- <i ngClass="{{node.class}}"></i> -->
            <mat-icon class="h-100" svgIcon="{{node.class}}"></mat-icon>
            <span class=" ml-1 p-0">
              {{node.name}}
            </span>
          </button>

          <button *ngIf="!node.badge" mat-button class="w-100  text-left" [routerLink]="node.routerLink">
            <!-- <i ngClass="{{node.class}}"></i> -->
            <mat-icon class="h-100" svgIcon="{{node.class}}"></mat-icon>
            <span class=" ml-1 p-0">
              {{node.name}}
            </span>
          </button>


        </mat-tree-node>

      </div>
      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding class="align-items-center w-100"
        [hidden]="node.hidden">
        <button mat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
          class="d-flex align-items-center w-100">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
          {{node.name}}
        </button>
      </mat-tree-node>
    </mat-tree>

  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <div class="row w-100 d-flex align-items-center">

        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 d-flex align-items-center justify-content-center">
          <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
        </div>


        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 d-flex align-items-center justify-content-center">
          <span> {{title}} - {{ currentTitle }} </span>
        </div>

        <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 d-flex align-items-end justify-content-end">
          <i class="fas fa-user-alt fa-md logout" [matMenuTriggerFor]="menu"></i>
          <mat-menu #menu="matMenu">
            <span mat-menu-item disabled>
              {{userName}}
            </span>
            <button mat-menu-item (click)="changeStore()" [hidden]="userRole == 'ADMIN'">
              <!-- <span style="width: 25%">
              <i class="fas fa-key fa-lg"></i>
            </span> -->
              <span class="text-left" style="width: 75%">
                <span>Alterar loja</span>
              </span>
            </button>
            <!--             <button mat-menu-item (click)="changePassword()">
            <span style="width: 25%">
              <i class="fas fa-key fa-lg"></i>
            </span>
              <span class="text-left" style="width: 75%">
                <span>Alterar senha</span>
              </span>
            </button> -->
            <!--             <button mat-menu-item (click)="openConfiguration()">
              <span style="width: 25%">
              <i class="fas fa-key fa-lg"></i>
            </span>
              <span class="text-left" style="width: 75%">
                <span>Configurações</span>
              </span>
            </button> -->
            <button mat-menu-item (click)="logout()">
              <!-- <span style="width: 25%">
              <i class="fas fa-sign-out-alt fa-lg logout"></i>
            </span> -->
              <span class="text-left" style="width: 75%">
                <span>Sair</span>
              </span>
            </button>
          </mat-menu>
        </div>
      </div>
    </mat-toolbar>

    <div class="container-fluid mt-4 position-relative">
      <router-outlet></router-outlet>

    </div>


  </mat-sidenav-content>

</mat-sidenav-container>
<div style="position: fixed; bottom: 0px; right: 0px; z-index: 9999; display: flex; flex-direction: column-reverse;" class="h-100 mt-4">
  <app-notifications class="w-100 " *ngFor="let item of notificacoes; let idx = index" 
    [notificationUrl]="'/main/requests/order/'+ item?.data?.order_id" 
    [notificationTitle]="item.title" 
    [notificationDescription]="item.mensage"
    (closeEmit)="closeNotification(idx)"
  ></app-notifications>
 
</div>