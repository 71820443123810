<div class="custom-not-found d-flex align-items-center">
    <ng-lottie width="65vw" height="65vh" [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>

    <div class="container text-center">
        <div class="pb-3">
            <span style="font-size: 150px; color: white;">
                404
            </span>
        </div>
        <div>
            <h1 style="color: white;"> Página não encontrada! </h1>
        </div>
        <div>
            <a mat-button [routerLink]="['']">Voltar à página inicial</a>
            <!-- <button mat-raised-button [routerLink]="['']">Voltar</button> -->
        </div>

    </div>
</div>