<div id="notificacao" *ngIf="notificationTitle && notificationDescription" class="my-1 mr-1"  >
   <div class="notificacao_1 notificacao_2 p-3" (click)="redirect()"  style="background-color: rgb(255, 255, 255);">
      <div class="notificacao_close" (click)="stopPropagation($event)"><mat-icon color="warn">close</mat-icon></div>
      <!-- <div class="notificacao_teste"></div> -->
      <div class="notificacao_corpo">
         <div class="notificacao_header">
            <font style="vertical-align: inherit;">
               <font style="vertical-align: inherit;">{{notificationTitle}}</font>
            </font>
         </div>
         <div class="notificacao_descricao">
            <font style="vertical-align: inherit;">
               <font style="vertical-align: inherit;">{{notificationDescription}} </font>
               <!-- <font style="vertical-align: inherit;">(18+)</font> -->
            </font>
         </div>
      </div>
   </div>
</div>