import { FormControl, FormGroup } from "@angular/forms";

/**
 * Faz a verificação da senha
 *
 */
export function ValidatorConfirmPassword(group: FormGroup) {

  let password = group.value.password;
  let repeatPassword = group.value.repeatPassword;

  if (password !== repeatPassword) return {
    confirmPassword: true,
    message: "As senhas não conferem!"
  };
  return null;
}