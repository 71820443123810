import { MatDialog } from '@angular/material/dialog';
import { StorageKey } from './../../storage/storage.model';
import { StorageService } from './../../storage/store.service';
import { RegisterManagerStore } from './../../../pages/store/register-manager-store/_models/RegisterManagerStore';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SellersService extends CrudService {

  endpoint = 'sellers'

  constructor(http: HttpClient, dialog: MatDialog) {
    super(http, dialog);
  }

  public async saveSeller(id, body): Promise<any> {
    let response = null;
    try {
      response = await this.http
        .post(this.url + '/' + this.endpoint + '/' + id + '/users', body)
        .toPromise();
    } catch (error) {
      response = this.errorHandler('POST', error);
    }
    return response;
  };

  public getById(seller_id: string | number): Promise<any> {
    return this.http.get(`${this.url}/${this.endpoint}/${seller_id}`).toPromise();
  }

  acceptSeller(seller_id: string, body ): Promise<any>{
    return this.http.post(`${this.url}/${this.endpoint}/${seller_id}/accept`, body).toPromise();
  }

  rejectSeller(seller_id: string, body): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/${seller_id}/reject`, body).toPromise();
  }

  createRecipient(seller_id: string, body): Promise<any> {
    return this.http.post(`${this.url}/${this.endpoint}/${seller_id}/recipient`, body).toPromise();
  }

  updateBankAccountStore(seller_id, bankAccount): Promise<any>{
    return this.http.put(`${this.url}/${this.endpoint}/${seller_id}/bank_account`, bankAccount).toPromise();
  }

  updateAddressStore(seller_id, address): Promise<any>{
    return this.http.put(`${this.url}/${this.endpoint}/${seller_id}/address`, address).toPromise();
  }

  updateWorkTime(seller_id, body): Promise<any>{
    return this.http.put(`${this.url}/${this.endpoint}/${seller_id}/opening_hours`, body).toPromise();
  }

  updateRecipient(seller_id, recipient_id, body): Promise<any>{
    return this.http.put(`${this.url}/${this.endpoint}/${seller_id}/recipient/${recipient_id}`, body).toPromise();
  }

  shippingToken(seller_id, body): Promise<any>{
    return this.http.post(`${this.url}/${this.endpoint}/${seller_id}/shipping_token`, body).toPromise();
  }

  checkIntegrationMe(seller_id: string): Promise<any>{
    return this.http.post(`${this.url}/${this.endpoint}/${seller_id}/check-integration/melhor-envio`, {}).toPromise();
  }

  toggleBlock(seller_id: string): Promise<any>{
    return this.http.post(`${this.url}/${this.endpoint}/${seller_id}/toggleBlock`, {}).toPromise();
  }

  createDeliveryPaymentMethod(seller_id: string, body: any): Promise<any>{
    return this.http.post(`${this.url}/${this.endpoint}/${seller_id}/delivery_payment_methods`, body).toPromise();
  }

  updateDeliveryPaymentMethod(seller_id: string, delivery_payment_method_id: string, body: any): Promise<any>{
    return this.http.put(`${this.url}/${this.endpoint}/${seller_id}/delivery_payment_methods/${delivery_payment_method_id}`, body).toPromise();
  }

  removeDeliveryPaymentMethod(seller_id: string, delivery_payment_method_id: string, ): Promise<any>{
    return this.http.delete(`${this.url}/${this.endpoint}/${seller_id}/delivery_payment_methods/${delivery_payment_method_id}`, ).toPromise();
  }

}
