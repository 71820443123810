import { ResultRequest } from './../_models/ResultRequest';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicPipe } from '../../pipe/dynamic-pipes/dynamic.pipe';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  providers: [DynamicPipe],
})
export class InformationComponent implements OnInit {
  openDetails: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ResultRequest,
    private dialog: MatDialog,
    private dialogInformation: MatDialogRef<InformationComponent>,
    private _snackBar: MatSnackBar
  ) {
   console.log(data)
  }

  teste(){
    this.openDetails = !this.openDetails;
  }

  ngOnInit(): void {
  }

  close() {
    console.log(this.data)
    if(this.data.error){
      this.dialogInformation.close();
    }else{
      this.dialog.closeAll();
    }
  }

  copyToClipboard() {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (JSON.stringify(this.data.returnError)));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.openSnackBar()
  }

  openSnackBar() {
    this._snackBar.open('Erro copiado!','', { duration: 2000} );
  }

}
