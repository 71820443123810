import { MatDialog } from '@angular/material/dialog';
import { RegisterManagerStore } from './../../../pages/store/register-manager-store/_models/RegisterManagerStore';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from '../crud/crud.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService extends CrudService{

  endpoint = 'users'
  url = environment.apiUrl;

  constructor(http: HttpClient, dialog: MatDialog) {
    super(http, dialog);
  }

  public async forgot(body): Promise<any> {
    let response = null;
    try {
      response = await this.http
        .post(this.url + '/' + this.endpoint + '/' + 'forgot', body)
        .toPromise();
    } catch (error) {
      response = this.errorHandler('POST', error);
    }
    return response;
  };

  public async resetPassword(body): Promise<any> {
    let response = null;
    try {
      response = await this.http
        .post(this.url + '/' + this.endpoint + '/' + 'reset', body)
        .toPromise();
    } catch (error) {
      response = this.errorHandler('POST', error);
    }
    return response;
  };

  public errorHandler(
    method: string,
    error: HttpErrorResponse,
  ): Promise<never> {
    console.error(
      `Error occurred during ${method} ${this.url + this.endpoint}`,
      error,
    );
    return Promise.reject(error);
  }
}
