import { PermissionGuard } from './../auth/guard/permission.guard';
import { NavigationComponent } from './navigation.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Role } from '../auth/_models/Role.enum';


const routes: Routes = [
  {
    path: '',
    canActivate: [PermissionGuard],
    component: NavigationComponent,
    children: [
      {
        path: 'store',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.ADMIN,]
        },
        loadChildren: () =>
          import('../store/store.module').then(
            m => m.StoreModule,
          ),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('../products/products.module').then(
            m => m.ProductsModule,
          ),
      },
      {
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.ADMIN,]
        },
        path: 'cupons',
        loadChildren: () =>
          import('../cupons/cupons.module').then(
            m => m.CuponsModule
          )
      },
      {
        path: 'dashboard',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.ADMIN, Role.MANAGER, Role.ATTENDANT]
        },
        loadChildren: () =>
          import('../dashboard/dashboard.module').then(
            m => m.DashboardModule,
          ),
      },
      {
        path: 'payment-methods',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.ADMIN,]
        },
        loadChildren: () =>
          import('../payment-methods/payment-methods.module').then(
            m => m.PaymentMethodsModule,
          ),
      },
      {
        path: 'requests',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.MANAGER, Role.ATTENDANT, Role.ADMIN]
        },
        loadChildren: () =>
          import('../requests/requests.module').then(
            m => m.RequestsModule,
          ),
      },
      {
        path: 'banners',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.ADMIN,]
        },
        loadChildren: () =>
          import('../banners/banners.module').then(
            m => m.BannersModule,
          ),
      },
      {
        path: 'faq-admin',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.ADMIN,]
        },
        loadChildren: () =>
          import('../faq-admin/faq-admin.module').then(
            m => m.FaqAdminModule
          )
      },
      {
        path: 'sell-with-us',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.ADMIN,]
        },
        loadChildren: () =>
          import('../sell-with-us-contact/sell-with-us-contact.module').then(
            m => m.SellWithUsContactModule
          )
      },
      {
        path: 'chat',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.MANAGER, Role.ADMIN, Role.ATTENDANT]
        },
        loadChildren: () => import('../order/chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: 'list-chats',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.ADMIN]
        },
        loadChildren: () => import('../order/list-chats/list-chats.module').then(m => m.ListChatsModule)
      },
      {
        path: 'shipping',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.MANAGER]
        },
        loadChildren: () =>
          import('../integration-shipping/integration-shipping.module').then(
            m => m.IntegrationShippingModule,
          ),
      },
      {
        path: 'delivery-configurations',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.MANAGER]
        },
        loadChildren: () =>
          import('../work-time/work-time.module').then(
            m => m.WorkTimeModule,
          )
      },
      {
        path: 'newsletter',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.ADMIN]
        },
        loadChildren: () =>
          import('../newsletter/newsletter.module').then(
            m => m.NewsletterModule,
          ),
      },
      {
        path: 'notify',
        canActivate: [PermissionGuard],
        data: {
          roles: [Role.ADMIN]
        },
        loadChildren: () => import('../notify/notify.module').then(m => m.NotifyModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NavigationRoutingModule { }
